<template>
  <div id="gift-card-batch-search">
    <breadcrumb-base :breadcrumb="breadcrumb" title="GiftCards">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>
    <div class="vx-row w-full">
      <div class="w-full md:w-1/3">
      </div>
      <div class="w-full md:w-1/3">
        <vx-card>
          <div class="">
            <vs-input
              class="w-full"
              v-model="batchNo"
              :placeholder="$t('BatchNo')"
              :label="$t('BatchNo')">
            </vs-input>
            <div class="py-2"/>
            <div class="flex justify-center">
              <vs-button @click="searchBatchNo">{{$t('Search')}}</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="w-full md:w-1/3">
      </div>
    </div>
    <div class="py-2"/>
    <vs-table-modified class="flex-1-1 w-full" v-if="cardDetails"
                       :data="[cardDetails.details]"
    >
      <template slot="thead">
        <vs-th>{{ $t('ProductName') }}</vs-th>
        <vs-th>{{ $t('ProductImage') }}</vs-th>
        <vs-th>{{ $t('Denomination') }}</vs-th>
        <vs-th >{{ $t('BatchNo') }}</vs-th>
        <vs-th >{{ $t('AssignedTo') }}</vs-th>
        <vs-th >{{ $t('AssignedAt') }}</vs-th>
        <vs-th >{{ $t('Status') }}</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.name }}</p>
          </vs-td>
          <vs-td>
            <img :src="'https://pjtelesoftgmbh.de' + tr.logo" :alt="tr.name" class="product-image" />
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">
              {{ tr.value | germanNumberFormat }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{
                tr.batch_no
              }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.assigned_to }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.assigned_at | dateFormat }}</p>
          </vs-td>
          <vs-td>
            <vs-chip :color="tr.status === 0 ? 'danger' : 'success'">{{
                tr.status === 0 ? $t('UnSold') : $t('Sold')
              }}
            </vs-chip>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table-modified>
  </div>
</template>

<script>
export default {
  name: "GiftCardBatchSearch",
  data() {
    return {
      batchNo: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'GiftCards', i18n: 'GiftCards', url: '/admin/giftcards/' },
        { title: 'Search', i18n: 'Search', active: true },
      ],
      cardDetails: null,
    }
  },
  methods: {
    searchBatchNo() {
      this.$vs.loading()
      return this.$store.dispatch('adminGiftCards/searchGiftCardBatch', { batch: this.batchNo })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.cardDetails = data;
        })
        .catch((error) => {
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.batchNo = '';
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg || 'Not found',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  }
}
</script>

<style scoped lang="scss">
#gift-card-batch-search {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }
}
.card-data {
  img {
    height: 100px;
  }
}
</style>
